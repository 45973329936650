import * as React from "react";
import "./src/styles/global.css";
import "./node_modules/slick-carousel/slick/slick.css";
import "./node_modules/slick-carousel/slick/slick-theme.css";
import CookieConsent from "react-cookie-consent";

export const wrapRootElement = ({ element }) => (
  <div>
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
      style={{
        background: "white",
        color: "black",
        width: 400,
        margin: "12px",
        textAlign: "right",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
      }}
      contentStyle={{
        textAlign: "left",
        flex: 1,
      }}
      buttonStyle={{
        background: "rgb(87 184 71)",
        color: "white",
      }}
    >
      <div className="font-bold ">Cookie Policy</div>
      <div className="mt-4">
        We do not use cookies other than for tracking site usage No data is
        shared with thirds parties or advertisers.
      </div>
      <p className="mt-2">
        We only use strictly necessary cookies that are needed to make the site
        functional.
      </p>
    </CookieConsent>

    {element}
  </div>
);
